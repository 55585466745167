<template>
  <div class="taruchot-new">
          <div class="field">
              <p>שם החברה</p>
              <el-input v-model="values.company_name" placeholder="שם החברה" />
          </div>
          <div class="field">
              <p>שם איש קשר</p>
              <el-input v-model="values.contact_name" placeholder="שם מלא" />
          </div>
          <div class="field">
              <p>דוא"ל של איש הקשר</p>
              <el-input v-model="values.email" placeholder="אימייל" />
          </div>
          <div class="field">
              <p>טלפון נייד של איש הקשר</p>
              <el-input v-model="values.phone" placeholder="טלפון" />
          </div>
          <div class="field">
            <p>מדינה</p>
            <el-select style="width:100%;" v-model="values.country"  filterable placeholder="בחר מקום">
                <el-option
                v-for="country in country_list"
                :key="country"
                :value="country"
                >
                </el-option>
            </el-select>
          </div>
          <div class="field">
              <p>תיאור  קצר</p>
              <el-input
                v-model="values.describe"
                :rows="3"
                type="textarea"
                placeholder="תאר במה החברה מתעסקת (שוקולד, חטיפים וכו)"
            />
          </div>
          <div class="field">
              <p>אתר אינטרנט</p>
              <el-input v-model="values.website" placeholder="https://www...." />
          </div>
          <div class="upload-box">
              <div class="upload-title">
                  <p>צלם תמונה של הדוכן</p>
              </div>
              <div class="upload-row">
                  <div class="upload-btn">
                      <el-button v-if="!is_upload_pending" @click="handleUpload('דוכן')" class="upload-btn"  type="primary">
                          <p style="white-space: pre-wrap; font-size:18px; text-align:center;">העלאה תמונה של הדוכן</p>
                      </el-button>
                      <el-button v-else class="upload-btn"  type="primary" disabled>אנא המתן להעלאה...</el-button>
                  </div>
                  <div class="upload-preview">
                      <img v-if="values.duchan_img.url" :src="values.duchan_img.url">
                  </div>
              </div>
          </div>
          <div class="upload-box">
              <div class="upload-title">
                  <p>צלם כרטיס ביקור</p>
              </div>
              <div class="upload-row">
                  <div class="upload-btn">
                      <el-button v-if="!is_upload_pending" @click="handleUpload('כרטיס ביקור')" class="upload-btn"  type="primary">
                          <p style="white-space: pre-wrap; font-size:18px; text-align:center;">העלאה כרטיס ביקור</p>
                      </el-button>
                      <el-button v-else class="upload-btn"  type="primary" disabled>אנא המתן להעלאה...</el-button>
                  </div>
                  <div class="upload-preview">
                     <img v-if="values.business_card.url" :src="values.business_card.url">
                  </div>
              </div>
          </div>
          <div class="upload-box">
              <div class="upload-title">
                  <p>תמונות מוצרים - עד 3 תמונות</p>
              </div>
              <div class="upload-row">
                  <div class="upload-btn">
                      <el-button v-if="!is_upload_pending" @click="handleUpload('מוצרים')" class="upload-btn"  type="primary">
                          <p style="white-space: pre-wrap; font-size:18px; text-align:center;">העלאה מוצרים</p>
                      </el-button>
                      <el-button v-else class="upload-btn"  type="primary" disabled>אנא המתן להעלאה...</el-button>
                  </div>
                    <div class="upload-preview-multy">
                        <template v-for="img in values.products_images" :key="img">
                            <img  :src="img.url">
                        </template> 
                    </div>
              </div>
          </div>
          <div class="field submit">
            <el-button @click="handle_submit" style="width:100%;" type="primary">שלח טופס</el-button>
          </div>

      <input v-show="false" id="upload-image" type="file" @change="handleChange"/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import use_taruchot_storage from '../../../Methods/use_taruchot_storage'
import {alert} from '../../../Methods/Msgs'
import {projectFirestore} from '../../../firebase/config'
export default {
    setup(){
        const type_upload = ref('')
        const error_msg = ref('')
        const {url, filePath, uploadImage, deleteImage} = use_taruchot_storage()
        const is_upload_pending = ref(false)
        const file = ref(null)
        const selected_country = ref('')
        const country_list=ref([
            'אזרביגאן',
            'איחוד האמירויות הערביות',
            'אינדונזיה',
            'איראן', 
            'אפגניסטן',
            'ארמניה',
            'בהוטאן',
            'מנאמה',
            'בנגלדש',
            'בנדר-סרי-בגוואן',
            'גורגיה', 
            'דרום קוריאה', 
            'האיים המלדיביים',
            'הודו',
            'וייטנאם', 
            'טגיקיסטן',
            'טורקיה', 
            'טורקמניסטן', 
            'טימור-לסטה',
            'יפן' ,
            'ירדן' ,
            'ישראל',
            'כווית', 
            'לאוס' ,
            'לבנון',
            'מונגוליה' ,
            'מיאנמר', 
            'מלזיה', 
            'נפאל', 
            'סוריה', 
            'סין' ,
            'סינגפור', 
            'סעודיה', 
            'סרי לנקה',
            'עומן',
            'עיראק', 
            'פיליפינים', 
            'פקיסטן' ,
            'צפון קוריאה',
            'קזחסטן', 
            'קטאר' ,
            'קירגיזסטן' ,
            'קמבודיה' ,
            'תאילנד' ,
            'תימן', 
            'טייואן',
            'הונג קונג',
            'מקאו',
            'אוסטריה' ,
            'אוקראינה', 
            'איטליה', 
            'איסלנד' ,
            'אירלנד' ,
            'אלבניה', 
            'אנדורה',
            'אסטוניה',
            'בולגריה',
            'בוסניה והרצגובינה' ,
            'בלארוס' ,
            'בלגיה' ,
            'בריטניה',
            'גרמניה' ,
            'דנמרק',
            'הולנד',
            'הונגריה',
            'וותיקן', 
            'יוון',
            'לוקסמבורג',
            'לטביה',
            'ליטא',
            'ליכטנשטיין',
            'מולדובה', 
            'מונאקו', 
            'מונטנגרו', 
            'מלטה', 
            'מקדוניה',
            'נורבגיה',
            'סלובניה',
            'סלובקיה',
            'סן מרינו',
            'ספרד' ,
            'סרביה', 
            'פולין',
            'פורטוגל',
            'פינלנד',
            'צכיה' ,
            'צרפת',
            'קפריסין',
            'קרואטיה',
            'רומניה' ,
            'רוסיה', 
            'שבדיה' ,
            'שוויץ',
            'אוגנדה',
            'אלג’יריה',
            'אנגולה',
            'אריתריאה',
            'אתיופיה',
            'בוצואנה',
            'בורונדי',
            'בורקינה-פאסו ',
            'בנין' , 
            'גאבון', 
            'גאנה' ,
            'גינאה',
            'גינאה-ביסאו', 
            'זימבבואה' ,
            'זמביה' ,
            'חוף השנהב' ,
            'טוגו' ,
            'טנזניה', 
            'לוב' ,
            'אורוגוואי',
            'איי בהאמה',
            'אל סלבדור',
            'אנטיגואה וברבודה', 
            'אקוודור', 
            'ארגנטינה', 
            'ארצות הברית ',
            'בליז' ,
            'ברבדוס', 
            'ברזיל' ,
            'גמייקה' ,
            'גואטמלה', 
            'גיאנה', 
            'גרנדה',
            'דומיניקה' ,
            'האיטי',
            'הונדורס',
            'הרפובליקה הדומיניקנית',
            'ונצואלה',
            'מכסיקו' ,
            'סנט לוסיה',
            'סנט קיטס ונוויס',
            'פנמה',
            'פרגוואי',
            'פרו' ,
            'צילה',
            'קובה', 
            'קולומביה' ,
            'קוסטה ריקה',
            'קנדה',
            'פורטו ריקו',
            'גאוודלופ – חסות צרפתית',
            'מרטיניק', 
            'גינאה הצרפתית',
            'קורסאו',
            'ארובה',
            'איי הבתולה האמריקאים',
            'איי קיימן ',
            'סנט מארטן ',
            'בחסות הממלכה המאוחדת',
            'איי הבתולה הבריטים ',
            'הקריבים ההולנדים',
            'אנגילה' ,
            'מונטסראט' ,
            'בחסות הממלכה המאוחדת'

        ])
        const values = ref({
            createdAt:new Date(),
            company_name:"",
            contact_name:"",
            email:"",
            phone:"",
            country:"",
            describe:"",
            website:"",
            duchan_img:{
                url:"",
                filePath:"",
                type:""
            },
            business_card:{
                url:"",
                filePath:"",
                type:""
            },
            products_images:[]
        })
        const handleUpload = (name)=>{
             type_upload.value = name
             document.getElementById("upload-image").click();
        }
        const handleChange = async(e)=>{
            is_upload_pending.value = true;
            const types = ["image/png", "image/jpeg", "image/jpg"];
            const selected = e.target.files[0];
            console.log(selected);
            if (selected && types.includes(selected.type)) {
                file.value = selected;
                if(type_upload.value=='דוכן'){
                    await uploadImage(file.value,values.value.company_name);
                    values.value.duchan_img.url = url.value
                    values.value.duchan_img.filePath = filePath.value,
                    values.value.duchan_img.type = selected.type
                }
                if(type_upload.value=='כרטיס ביקור'){
                    await uploadImage(file.value,values.value.company_name);
                    values.value.business_card.url = url.value
                    values.value.business_card.filePath = filePath.value,
                    values.value.business_card.type = selected.type
                }
                if(type_upload.value=='מוצרים'){
                    if (values.value.products_images.length < 3){
                        await uploadImage(file.value,values.value.company_name);
                        values.value.products_images.push({
                        url: url.value,
                        filePath: filePath.value,
                        type: selected.type
                        });
                    }
                }
            } else {
                console.log("error");
                file.value = null;
                alert('error','שים לב','סוג קובץ זה אינו נתמך, נא להעלות קובץ תמונה או PDF בלבד!')
            }
            is_upload_pending.value = false;
        }

        const handle_submit = async()=>{
            if(!validation()){
                alert('error','שים לב!',error_msg.value)
            }else{
                await store_in_db()
                alert('success','הצלחה','הטופס נשלח בהצלחה')
                .then(()=>{
                    reset_data()
                })
            }
            
        }

        const validation=()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'company_name':
                        if(!value){
                            error_msg.value = 'עליך  להזין את שם החברה'
                            return false
                        }
                        break;
                    case 'contact_name':
                        if(!value){
                            error_msg.value = 'עליך להזין את שם איש הקשר'
                            return false
                        }
                        break;
                    case 'email':
                        if(!value){
                            error_msg.value = 'עליך להזין כתובת אמייל'
                            return false
                        }
                        break;
                    case 'phone':
                        if(!value){
                            error_msg.value = 'עליך להזין טלפון'
                            return false
                        }
                        break;
                    case 'country':
                        if(!value){
                            error_msg.value = 'עליך להזין מדינה '
                            return false
                        }
                        break;
                    case 'describe':
                        if(!value){
                            error_msg.value = 'עליך להזין תיאור '
                            return false
                        }
                        break;
                    case 'website':
                        if(!value){
                            error_msg.value = 'עליך להזין כתובת אתר אינטרנט '
                            return false
                        }
                        break;
                    case 'duchan_img':
                        if(!value.url){
                            error_msg.value = 'עליך להעלות תמונה של הדוכן '
                            return false
                        }
                        break;
                    case 'business_card':
                        if(!value.url){
                            error_msg.value = 'עליך להעלות תמונה של כרטיס ביקור '
                            return false
                        }
                        break;
                    case 'products_images':
                        if(value.length==0){
                            error_msg.value = 'עליך להעלות לפחות תמונה של מוצר אחד'
                            return false
                        }
                        break;
                }
            }

            return true
        }

        const store_in_db=async()=>{
            const doc = projectFirestore.collection('Applications').doc('dRqgaSHAt0EYwPMAieqP')
            .collection('Forms').doc()
            await doc.set({...values.value,id:doc.id})
        }

        const reset_data=()=>{
            values.value = {
                createdAt:new Date(),
                company_name:"",
                contact_name:"",
                email:"",
                phone:"",
                country:"",
                describe:"",
                website:"",
                duchan_img:{
                    url:"",
                    filePath:"",
                    type:""
                },
                business_card:{
                    url:"",
                    filePath:"",
                    type:""
                },
                products_images:[]
            }
        }
        return{error_msg,country_list,selected_country,values,handleChange,handle_submit,is_upload_pending,handleUpload}
    }
}
</script>

<style scoped>
    .taruchot-new{
        width: 100%;
        height: 100%;
        background: #fff;
        overflow:hidden;
        overflow-y: auto;
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 5px;
    }
    .wrapper{
        padding: 15px;
        margin: 0 auto;
        width: 80%;
        min-height: 100%;
    }
    .field{
        margin-bottom: 15px;
        width: 100%;
        max-width: 380px;
        height: fit-content;
    }
    .uploades .image-upload{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .uploades .image-upload img{
        max-width: 100%;
        max-height: calc(100% - 30px);
    }
    .field.uploades{
        display: flex;
        flex-direction: row;
        height: 130px;
        overflow: hidden;
    }
    .upload-box{
        width: 100%;
        max-width: 380px;
        height: 120px;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin-bottom: 5px;
    }
    .upload-title{
        width: 100%;
        height: 25px;
        font-size: 20px;
        text-align: start;
    }
    .upload-row{
        width: 100%;
        height: calc(100% - 25px);
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
    }
    .upload-btn{
        width: 80px;
        height: 100%;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 5px;
    }
    .upload-preview , .upload-preview-multy{
        padding-right: 10px;
        width: calc(100% - 80px);
        height: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-shrink: 0;
    }
    .upload-preview img{
        max-width: 100%;
        max-height: 100%;
    }
    .upload-preview-multy img{
        max-width: calc(100% / 3);
        max-height: 100%
    }
    .field.submit{
        margin-top: 20px;
    }
</style>